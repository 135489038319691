.ImageViewer {
  display: flex;
  flex-direction: column;
}

.ImageViewer-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.ImageViewer-window-image {
  width: 500px;
  height: 400px;
}

.ImageViewer-window-video {
  width: 500px;
  height: 400px;
  overflow: hidden;
}

.ImageViewer-thumb {
  display: flex;
  flex-direction: row;
  list-style:none;
  padding: 0;
  margin: 32px 0;
  height: 100px;
  list-style: none;
  overflow-y: hidden;
  overflow-x: auto;
}

.ImageViewer-thumb-img {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 4px solid #232931;
  box-sizing: border-box;
}

.ImageViewer-thumb-img.selected {
  border: 4px solid #eeeeee80;
}
