.CryptoSelector-selector {
  position: absolute;
  max-width: 240px;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #232931;
  border: 1px solid #232931;
  box-shadow: 0 0 32px 0 #181C22;
  border-radius: 4px;
  z-index: 1;
}

.CryptoSelector-selector-img {
  width: 24px;
  border-radius: 100%;
}

.CryptoSelector-selector-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #232931;
  cursor: pointer;
  padding: 8px;
  margin: 8px 0;
}

.CryptoSelector-selector-item:hover {
  background-color: #3d454f;
}

.CryptoSelector-selector-text {
  margin-left: 8px;
}

.CryptoSelector-button {
  display: flex;
  align-items: center;
}

.CryptoSelector-button-text {
  margin-left: 8px;
}
