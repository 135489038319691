.Input {
  width: 100%;
  height: 40px;
  background-color: #393E46;
  border: 0;
  border-radius: 4px;
  color: #eeeeee;
  font-size: 24px;
  font-weight: 800;
  padding: 4px 8px;
  box-sizing: border-box;
}

.Input:focus-visible {
  outline: 0;
  background-color: #13161A;
}

.Input::selection {
  background-color: #393E46;
}

.Input.textarea {
  height: auto;
}
