.DealId-input-container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 40px;
  margin: 0;
  cursor: text;
}

.DealId-input {
  width: 100%;
  height: 100%;
  background-color: #393E46;
  color: #eee;
  border: 0;
  outline: 0;
  padding: 0 8px;
  font-size: 24px;
  box-shadow: 0 0 8px 0 #181c22;
  border-radius: 4px;
  z-index: 1;
}

.DealId-input:focus {
  background-color: #13161A;
}

.DealId-input-placeholder {
  position: absolute;
  width: 100%;
  color: #c4c4c4;
  text-align: center;
  font-weight: 600;
  z-index: 2;
}

.DealId-arrow {
  position: absolute;
  right: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  width: 24px;
  transform: scale(0);
  transition: .05s ease-in;
  cursor: pointer;
  z-index: 1;
}

.DealId-arrow-show {
  transform: scale(1);
}
