html {
}

body {
  margin: 0;
  background-color: #232931;
  color: #fff;
  font-family: 'Inter', 'Roboto Mono', sans-serif, monospace, system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #232931;
}

::-webkit-scrollbar-thumb {
  background-color: #eeeeee80;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.number,
code {
  font-family: 'Roboto Mono', monospace;
}

a {
  color: #eee;
}
