.Address {
  display: flex;
  position: relative;
  padding: 4px;
  background-color: #222;
  border-radius: 4px;
  cursor: pointer;
  z-index: 9;
}

.Address:hover {
  background-color: #2c3137;
}

.Address-img {
  width: 16px;
}

.Address-text {
  margin: 0 8px;
}

.Address-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 8px;
  margin-top: 0px;
  background-color: #222;
  box-sizing: border-box;
  border-radius: 4px;
}

.Address-link {
  display: flex;
  padding: 4px;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  justify-content: flex-start;
}

.Address-link:hover {
  background-color: #2c3137;
}
