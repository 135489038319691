.dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #232931;
  box-shadow: 0 0 32px 0 #181C22;
  color: #eeeeee;
  padding: 16px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 99;
}

.dialog-head {
  position: relative;
}

.dialog-title {
  text-align: left;
}

.dialog-close-button {
  position: absolute;
  width: 25px;
  right: 0;
  top: 0;
  transform: translate(-50%, 50%);
  cursor: pointer;
}

.dialog-close-button img {
  width: 100%;
}

.dialog-content {
  margin-top: 16px;
}

