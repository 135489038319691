.Button {
  height: 40px;
  font-weight: 800;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: .8px;
  cursor: pointer;
  align-items: center;
  transition: .2s ease-out;
}

.Button-primary {
  background-color: transparent;
  border: 1px solid #EEEEEE24;
  color: #EEEEEE;
}

.Button-w120 {
  width: 120px;
  background-color: transparent;
  border: 1px solid #EEEEEE24;
  color: #EEEEEE;
}

.Button-w120:active, .Button-primary:active {
  border: 1px solid #EEEEEE;
}

.Button-w160 {
  width: 160px;
  background-color: transparent;
  border: 1px solid #EEEEEE24;
  color: #EEEEEE;
}

.Button-w160:active, .Button-primary:active {
  border: 1px solid #EEEEEE;
}

.Button-w210 {
  width: 210px;
  background-color: transparent;
  border: 1px solid #EEEEEE24;
  /* border: 1px solid #EEEEEEdb; */
  /* border-radius: 1px; */
  color: #EEEEEEdb;
}

.Button-w210:active, .Button-primary:active {
  border: 1px solid #EEEEEE;
}

.Button-danger {
  background-color: transparent;
  border: 1px solid #D72323;
  color: #D72323;
}

.Button-danger:active {
  background-color: #D72323;
  color: #EEEEEE;
}

.Button-large {
  width: 100%;
}

.Button-small {
  height: 32px;
  padding: 0 8px;
  background-color: transparent;
  border: 1px solid #EEEEEE24;
  color: #EEEEEE;
}

.Button-disabeld {
  background-color: #13161A;
  border: 1px solid #13161A !important;
  color: #9D9D9D50;
  cursor: default;
}

.Button-h32 {
  height: 32px;
}
