.Landing {
  display: flex;
  color: #eeeeee;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: -54px;
}

.Landing-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.Landing-input-container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 210px;
  height: 40px;
}

.Landing-input {
  width: 100%;
  height: 100%;
  background-color: #2222228a;
  color: #eee;
  border: 0;
  outline: 0;
  padding: 0 8px;
  font-size: 24px;
  z-index: 1;
}

.Landing-input-placeholder {
  position: absolute;
  color: #c4c4c4;
  text-align: left;
  margin-left: 8px;
  font-weight: 600;
  z-index: 0;
}

.Landing-arrow {
  position: absolute;
  right: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  width: 24px;
  transform: scale(0);
  transition: .2s ease-in;
  z-index: 1;
}

.Landing-arrow-show {
  transform: scale(1);
}
