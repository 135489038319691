.Create {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -27px;
}

.Create-row {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.Create-row-flex-row {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.Create-input-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}

.Create-crypto-selector {
  position: absolute;
  margin: 8px 0;
  right: 0;
}

.Create-help {
  position: absolute;
  width: 100%;
  left: -105%;
  font-size: 14px;
  text-align: left;
}

.Create-dealId-input {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -80px;
  width: 100%;
  left: 0;
}
