.Lock {
  max-width: 1600px;
  margin: 0 auto;
  color: #fff;
}

.Lock-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Lock-container {
  display: flex;
  height: 600px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Lock-transfer {
  align-items: center;
  border-top: 1px dashed #eeeeeea1;
  border-bottom: 1px dashed #eeeeeea1;
}

.Lock-down-arrow {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

.Lock-transfer-span {
  margin-left: 8px;
}

.Lock-title {
  font-size: 24px;
}

.Lock-title strong {
  font-size: 32px;
}

.Lock-card-header {
  display: flex;
  flex-direction: row;
}

.Lock-card-title {
  font-size: 16px;
  font-weight: 800;
  text-align: left;
  align-items: center;
}

.Lock-card-header-left {
  display: flex;
}

.Lock-card-header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.Lock-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Lock-link-img {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  cursor: pointer;
}

.Lock-link-img-etherscan {
  width: 22px;
  height: 22px;
}

.Lock-address {
  justify-content: space-between;
  align-items: center;
}

.Lock-row {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.Lock-col {
  display: flex;
  margin: 8px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.Lock-col.Button-col {
  justify-content: center;
}

.Lock-amount {
  justify-content: space-between;
}

.Lock-amount-left {
}

.Lock-amount-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Lock-bolder {
  display: flex;
  font-size: 16px;
  font-weight: 800;
  text-align: right;
  align-items: center;
}

.Lock-buttons {
  display: flex;
  margin-top: 56px;
  justify-content: space-evenly;
}

.Lock-buttons button {
  margin: 8px;
}

.Lock-id-copy {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Lock-id-text {
  font-size: 24px;
  margin-right: 8px;
}

.Lock-id-copy-icon {
  font-size: 12px;
}

.Lock-end {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #232931f2;
  justify-content: center;
  align-items: center;
}

.Lock-help {
  position: absolute;
  width: 100%;
  left: -105%;
  font-size: 14px;
  text-align: right;
  margin-top: 8px;
}

.Lock-tips {
  text-align: left;
  font-size: 14px;
}

.Lock-text {
  display: flex;
  width: 120px;
  height: 32px;
  color: #eee;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: .8px;
  align-items: center;
  justify-content: center;
}

.Lock-notfound {
  display: flex;
  flex-direction: column;
}

.Lock-dealId-input {
  position: absolute;
  width: 100%;
  top: -80px;
  left: 0;
}

.Lock-balance {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  margin-top: -4px;
}

.Lock-logs {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 280px;
  right: -300px;
  top: 0;
  text-align: right;
  font-family: 'Roboto Mono';
}

@media screen and (max-width: 768px) {
  .Lock-logs {
    display: none;
  }
}

.Lock-logs a {
  text-decoration: none;
}

.Lock-logs a:hover {
  color: #eee;
}

.Lock-logs-head {
  font-size: 16px;
  margin-bottom: 16px;
}

.Lock-logs-list {
  display: flex;
  flex-direction: column;
  height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px;
}

.Lock-log {
  margin-bottom: 16px;
}

.Lock-log-txn {
  color: #cacaca;
}

.Lock-log-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lock-log-addr {
  font-size: 14px;
  color: #cacaca;
}

.Lock-log-name {
  font-size: 14px;
  text-transform: capitalize;
}

