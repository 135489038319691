.Detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1024px;
  color: #fff;
}

.Detail-row {
  display: flex;
  margin: 16px 0;
  align-items: center;
}

.Detail-edit-row {
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px 0;
  align-items: flex-start;
}

.Detail-edit-row > div:first-child {
  margin-bottom: 8px;
}


.Detail-lightbox {
  height: 400px;
}

.Detail-img {
  height: 300px;
}
