.Marketplace {
  width: 100vw;
  min-height: 100vh;
  padding: 24px;
  margin: auto;
}

.Marketplace-searchbar {
  width: 800px;
  margin: 80px auto;
}

.Marketplace-trade {
  margin: 80px auto;
}

.Marketplace-stuffs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 80px auto;
}

.Marketplace-card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

.Marketplace-card-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.Marketplace-card-no-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  color: #a5a5a5;
  font-weight: 100;
  text-decoration: none;
}

.Marketplace-card-img-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #000;
  opacity: 0.38;
}

.Marketplace-card-link {
  text-decoration: none;
}

.Marketplace h3,h4,h5 {
  margin: 0;
}
