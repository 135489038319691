.App {
  max-width: 100vw;
  text-align: center;
}

.App-logo {
  width: calc(12px + 2vmin);
  margin: 4px;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-nav {
  max-width: 1600px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #EEEEEE;
  margin: 0 auto;
  padding: 0 24px;
}

.App-nav-left,
.App-nav-right {
  display: flex;
  /* margin-right: 24px; */
  align-items: center;
}

.App-nav-left .App-nav-left-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  transition: .1s ease-in;
}

.App-nav-left .App-nav-left-logo:hover {
  opacity: .6;
}

.App-nav-left-menu,
.App-nav-right-menu {
  display: flex;
  list-style: none;
}

.App-nav-left-menu li,
.App-nav-right-menu li {
  margin-right: 32px;
}

.App-nav-left-menu a,
.App-nav-right-menu a {
  border: 1px solid #eeeeee00;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 800;
  padding: 8px 16px;
}

.App-nav-left-menu a.active,
.App-nav-right-menu a.active {
  border: 1px solid #eeeeee80;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232931;
  max-width: 100vw;
  height: calc(100vh - 64px);
  flex-wrap: wrap;
}

.App-h1 {
  font-size: calc(8px + 2vmin);
  margin: 0;
}

