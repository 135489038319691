.Trade {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Trade-row {
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px 0;
  align-items: flex-start;
}

.Trade-row > div:first-child {
  margin-bottom: 8px;
}

