.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  /*height: 360px;*/
  background-color: #232931;
  border: 1px solid #232931;
  box-shadow: 0 0 32px 0 #181C22;
  border-radius: 16px;
  color: #eeeeee;
  padding: 16px 32px;
  box-sizing: border-box;
}

.Card-wide {
  width: 100%;
  max-width: 640px;
}

.Card-title {
  justify-content: center;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 16px;
}

.Card-label {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.Card-label.inline {
  width: calc(100% - 25px);
}

.Card-label-right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  align-self: end;
}

.Card-label-small {
  font-size: 14px;
}
