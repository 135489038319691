.WalletItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #232931;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px;
}

.WalletItem:hover {
  border: 1px solid #fff;
}

.WalletItem img {
  width: 50px;
  margin-right: 16px;
}

.WalletAddress {
  display: flex;
  align-items: center;
}

.WalletNetwork {
  margin: 0 8px;
  text-transform: capitalize;
}
