.Checkbox {
  position: relative;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  background-color: #393E46;
  cursor: pointer;
  border-radius: 4px;
}

.Checkbox.checked {
  background-color: #4ECCA3;
}

.Checkbox.checked:hover {
  background-color: #4ECCA3;
}

.Checkbox:hover {
  background-color: #2F333A;
}

.Checkbox-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
