.Networks {
  position: relative;
  padding: 0 8px;
  text-transform: capitalize;
  z-index: 9;
}

.Networks ul {
  position: absolute;
  list-style: none;
  width: 160px;
  left: 0;
  padding: 0;
  margin: 0 0 0 8px;
  background-color: #222;
  border-radius: 8px;
  box-shadow: 1px 1px 16px #222;
  text-align: left;
  text-transform: capitalize;
}

.Networks ul li {
  display: flex;
  padding: 8px;
  cursor: pointer;
  align-items: center;
}

.Networks ul li:hover {
  background-color: #444;
}

.NetworkName {
  display: flex;
  align-items: center;
  background-color: #222;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
